import Hero from '../components/services/Hero';
import WhatWeDo from '../components/services/WhatWeDo';
import Bottom from '../components/services/Bottom';
export default function ServicesPage(){
  return(
    <div className="">
      <Hero/>
      <WhatWeDo/>
      <Bottom/>
    </div>
  )
}