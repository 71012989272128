import Hero from '../components/home/Hero';
import WhoWeAre from '../components/home/WhoWeAre';
import WhatWeDo from '../components/home/WhatWeDo';
import HowWeStart from '../components/home/HowWeStart';
import Industries from '../components/home/Industries';
import Clients from '../components/home/Clients';
import Blogs from '../components/home/Blogs';
import Contacts from '../components/home/Contacts';
export default function HomePage(){
  return (
    <div className="w-full h-full">
      <Hero/>
      <WhoWeAre/>
      <WhatWeDo/>
      <HowWeStart/>
      <Industries/>
      <Clients/>
      <Blogs/>
      <Contacts/>
    </div>
  )
}