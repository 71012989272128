import tanwir from "../../assets/Tanwir.svg";
import ofb from "../../assets/Ofb.svg";
import alltrip from "../../assets/Alltrip.svg";
import realAvia from "../../assets/real-avia.svg";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
export default function Clients() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#051022] text-white pt-6 pb-12 md:pb-24">
      <div className="container">
        <h2 className="mb-4 sm:mb-8 md:mb-16 text-base sm:text-lg md:text-xl lg:text-2xl leading-6 md:leading-7 text-center font-medium uppercase">
        {t('clients')}
        </h2>
        <div className="w-full">
          <Swiper
            slidesPerView={7}
            autoplay={{
              delay: 1000,
              disableOnInteraction: true,
            }}
            loop={true}
            modules={[Autoplay]}
            className="mySwiper w-full"
          >
            <SwiperSlide>
              <img
                className="max-sm:scale-75 max-md:scale-90 max-w-[100px] h-auto mx-auto"
                src={alltrip}
                alt="Alltrip"
                width={100}
              />
            </SwiperSlide>
            <SwiperSlide></SwiperSlide>
            <SwiperSlide>
              <img
                className="max-sm:scale-75 max-md:scale-90 max-w-[90px] h-auto mx-auto"
                src={ofb}
                alt="OFB"
                width={90}
              />
            </SwiperSlide>
            <SwiperSlide></SwiperSlide>
            <SwiperSlide>
              <img
                className="max-sm:scale-75 max-md:scale-90 max-w-[160px] h-auto mx-auto"
                src={realAvia}
                alt="Real Avia"
                width={160}
              />
            </SwiperSlide>
            <SwiperSlide></SwiperSlide>
            <SwiperSlide>
              <img
                className="max-sm:scale-75 max-md:scale-90 max-w-[200px] h-auto mx-auto"
                src={tanwir}
                alt="Tanwir"
                width={200}
              />
            </SwiperSlide>
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
