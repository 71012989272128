import phoneNumber from "../../assets/phone-number.svg";
import emailAddress from "../../assets/email-address.svg";
import location from "../../assets/location.svg";
import { useTranslation } from "react-i18next";
export default function Contacts() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#FCFBFB] py-14 text-[#051022]" id="contacts">
      <div className="container flex max-md:flex-col gap-3 md:gap-x-4 lg:gap-x-8">
        <div className="flex flex-col gap-y-4">
          <div className="min-w-[280px] lg:min-w-[370px] w-full flex items-center gap-x-3 lg:gap-x-6 p-3 lg:p-6 border border-[#7E808933] bg-white shadow-[4px_4px_6px_0_#00000040] hover:scale-95 transition-all ease-in-out duration-500">
            <img src={phoneNumber} alt="phone number" width={48} height={48} />
            <div className="flex flex-col">
              <p className="mb-2 font-medium text-base leading-5 text-[#959595]">{t('phone')}</p>
              <a className="font-medium text-sm lg:text-base leading-5" href="tel:+998337000901">+998 33 700 09 01</a>
            </div>
          </div>
          <div className="min-w-[280px] lg:min-w-[370px] w-full flex items-center gap-x-3 lg:gap-x-6 p-3 lg:p-6 border border-[#7E808933] bg-white shadow-[4px_4px_6px_0_#00000040] hover:scale-95 transition-all ease-in-out duration-500">
            <img src={emailAddress} alt="email" width={48} height={48} />
            <div className="flex flex-col">
              <p className="mb-2 font-medium text-base leading-5 text-[#959595]">{t('email')}</p>
              <a className="font-medium text-sm lg:text-base leading-5 truncate" href="mailto:maxcybercom@gmail.com">maxcybercom@gmail.com</a>
            </div>
          </div>
          <div className="min-w-[280px] lg:min-w-[370px] w-full flex items-center gap-x-3 lg:gap-x-6 p-3 lg:p-6 border border-[#7E808933] bg-white shadow-[4px_4px_6px_0_#00000040] hover:scale-95 transition-all ease-in-out duration-500">
            <img src={location} alt="location" width={48} height={48} />
            <div className="flex flex-col">
              <p className="mb-2 font-medium text-base leading-5 text-[#959595]">{t('address')}</p>
              <p className="font-medium text-sm lg:text-base leading-5">Uzbekistan, Farg'ona, Qo'qon</p>
            </div>
          </div>
        </div>
        <div className="w-full p-4 sm:p-6 sm:pr-12 bg-white border border-[#7E808933] shadow-[3px_3px_8px_0_#00000040]">
          <h4 className="mb-3.5 font-semibold text-lg md:text-xl leading-6">{t('send message')}</h4>
          <p className="max-w-[430px] mb-4 text-sm md:text-base leading-5 text-[#959595]">He’s so similar to me. In person, we’re just weird and silly and stupid together.</p>
          <form action="">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-x-6 mb-4">
              <input className="w-full bg-[#FCFBFB] border border-[#7E808933] rounded-sm px-3.5 py-2.5 text-sm text-[#7E7E7E] leading-4 font-light focus:outline-none" type="email" name="email" id="email" placeholder="Email" />
              <input className="w-full bg-[#FCFBFB] border border-[#7E808933] rounded-sm px-3.5 py-2.5 text-sm text-[#7E7E7E] leading-4 font-light focus:outline-none" type="text" name="fio" id="fio" placeholder="Full name" />
            </div>
            <textarea className="w-full max-h-[130px] h-full mb-6 overflow-y-auto bg-[#FCFBFB] border border-[#7E808933] rounded-sm px-3.5 py-2.5 text-sm text-[#7E7E7E] leading-4 font-light resize-none focus:outline-none" name="message" id="message" cols="30" rows="10" placeholder="Your message"></textarea>
            <button className="w-full text-center bg-[#051022] border-2 border-[#051022] hover:bg-transparent hover:text-[#051022] rounded-lg py-2.5 font-bold text-white text-sm leading-4" type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}
