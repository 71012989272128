import contact from "../../assets/contact.svg";
import analysis from "../../assets/analysis.svg";
import team from "../../assets/team.svg";
import proposal from "../../assets/proposal.svg";
import start from "../../assets/start.svg";
import { useTranslation } from "react-i18next";
export default function HowWeStar() {
  const { t } = useTranslation();
  return (
    <div className="container py-12 md:py-20 text-[#051022]">
      <h2 className="mb-6 md:mb-12 text-lg sm:text-xl md:text-2xl font-bold leading-8 text-center">
      {t('how we start')}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-6 lg:gap-16">
        <div className="flex flex-row sm:flex-col items-center max-sm:gap-x-4">
          <img className="mb-0 sm:mb-4 max-sm:scale-75" src={contact} alt="contact" />
          <div className="">
            <h5 className="mb-0 sm:mb-4 lg:mb-6 font-bold text-base sm:text-xl lg:text-2xl leading-5 sm:leading-7 text-left sm:text-center">
            {t('contact')}
            </h5>
            <p className="text-left sm:text-center text-sm sm:text-base leading-5">
              Lorem ipsum dolor sit amet consectetur. Libero neque donec{" "}
            </p>
          </div>
        </div>
        <div className="flex flex-row sm:flex-col items-center max-sm:gap-x-4">
          <img className="mb-0 sm:mb-4 max-sm:scale-75" src={analysis} alt="analysis" />
          <div className="">
            <h5 className="mb-0 sm:mb-4 lg:mb-6 font-bold text-base sm:text-xl lg:text-2xl leading-5 sm:leading-7 text-left sm:text-center">
            {t('analysis')}
            </h5>
            <p className="text-left sm:text-center text-sm sm:text-base leading-5">
              Lorem ipsum dolor sit amet consectetur. Libero neque donec{" "}
            </p>
          </div>
        </div>
        <div className="flex flex-row sm:flex-col items-center max-sm:gap-x-4">
          <img className="mb-0 sm:mb-4 max-sm:scale-75" src={proposal} alt="proposal" />
          <div className="">
            <h5 className="mb-0 sm:mb-4 lg:mb-6 font-bold text-base sm:text-xl lg:text-2xl leading-5 sm:leading-7 text-left sm:text-center">
            {t('proposal')}
            </h5>
            <p className="text-left sm:text-center text-sm sm:text-base leading-5">
              Lorem ipsum dolor sit amet consectetur. Libero neque donec{" "}
            </p>
          </div>
        </div>
        <div className="flex flex-row sm:flex-col items-center max-sm:gap-x-4">
          <img className="mb-0 sm:mb-4 max-sm:scale-75" src={team} alt="team" />
          <div className="">
            <h5 className="mb-0 sm:mb-4 lg:mb-6 font-bold text-base sm:text-xl lg:text-2xl leading-5 sm:leading-7 text-left sm:text-center">
            {t('team')}
            </h5>
            <p className="text-left sm:text-center text-sm sm:text-base leading-5">
              Lorem ipsum dolor sit amet consectetur. Libero neque donec{" "}
            </p>
          </div>
        </div>
        <div className="flex flex-row sm:flex-col items-center max-sm:gap-x-4">
          <img className="mb-0 sm:mb-4 max-sm:scale-75" src={start} alt="start" />
          <div className="">
            <h5 className="mb-0 sm:mb-4 lg:mb-6 font-bold text-base sm:text-xl lg:text-2xl leading-5 sm:leading-7 text-left sm:text-center">
            {t('team')}
            </h5>
            <p className="text-left sm:text-center text-sm sm:text-base leading-5">
              Lorem ipsum dolor sit amet consectetur. Libero neque donec{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
