import blogs from '../../assets/blogs.png';
import chevronRight from '../../assets/chevron-right.svg';
import { useNavigate } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import {getData} from '../Requests';
export default function Blogs(){
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  useLayoutEffect(()=>{
    getData('/page/by-page-type-code?pageTypeCode=3')
    .then((res) =>{
      setBlogs(res.data);
    })
    .catch((err) =>{
      console.log(err);
    })
  },[])
  const toBlogs = (id) =>{
    navigate("/blogs/" + id);
  }
  return(
    <div className="container text-[#051022] pt-10 md:pt-16 pb-8 md:pb-16" id='blogs'>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 lg:gap-10">
        {blogs.map((blog, key)=>{
          return(
            <div className="felx flex-col" key={key}>
            <img className='mb-2 md:mb-4' src={blog.image} alt="blogs" width={355} height={261} />
            <h4 className="mb-2 md:mb-4 text-base sm:text-lg md:text-xl font-bold leading-6">{blog.title}</h4>
            <p className="text-xs sm:text-sm md:text-base leading-4 sm:leading-5 text-[#7E8089]">{blog.description}</p>
            <button onClick={() => toBlogs(blog.id)} className="flex items-center gap-x-1 font-bold text-base leading-5 text-[#8C0F95] hover:scale-105">Read <img src={chevronRight} alt="right" width={24} height={24}/></button>
          </div>
          )
        })}
      </div>
    </div>
  )
}