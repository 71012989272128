import address from '../../assets/address.png';
export default function Address(){
  return(
    <div className="container pt-4 pb-12 sm:pb-20 md:pb-28 text-[#051022]">
      <h2 className="mb-4 md:mb-6 text-lg sm:text-xl md:text-2xl lg:text-[32px] font-bold leading-8 text-center">
      Find us on map
      </h2>
      <p className="max-w-[620px] mx-auto mb-6 sm:mb-10 md:mb-14 text-center text-[#959595] text-sm sm:text-base md:text-xl">
        Lorem ipsum dolor sit amet consectetur. Libero neque donec etiam pretium
        nibh molestie scelerisque urna justo
      </p>
      <img className='block mx-auto' src={address} alt="Address" />
    </div>
  )
}