import axios from "axios";
export const axiosInstance = axios.create({
  baseURL: 'https://backend.max.kube.uz/api/v1',
});
let lang = localStorage.getItem('lang');
axiosInstance.interceptors.request.use(
  async (config) => {  
    if (config.url?.includes("?")) {
      config.url = config.url + "&lang="+lang ;
    } else {
      config.url = config.url + "?lang="+lang;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 && error.data.code === -20) {
      console.log(error);
    }
    return Promise.reject(error);
  }
);