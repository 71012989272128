import chevronRight from '../../assets/chevron-right.svg';
import { useNavigate } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import {getData} from '../Requests';
import { useTranslation } from "react-i18next";
export default function Blogs(){
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  useLayoutEffect(()=>{
    getData('/page/by-page-type-code?pageTypeCode=3')
    .then((res) =>{
      setBlogs(res.data);
    })
    .catch((err) =>{
      console.log(err);
    })
  },[])
  const toBlogs = (id) =>{
    navigate("/blogs/" + id);
  }
  return(
    <div className="container text-[#051022] pt-10 md:pt-16 lg:pt-28 pb-8 md:pb-16 lg:pb-24" id='blogs'>
      <h2 className="mb-3 lg:mb-6 text-lg md:text-xl lg:text-[32px] font-bold leading-6 md:leading-10 text-center">{t('blogs')}<span className='text-[#8C0F95]'>|</span> {t('news')} <span className='text-[#8C0F95]'>|</span> {t('insights')}</h2>
      <p className="mb-4 md:mb-8 lg:mb-14 max-w-[620px] mx-auto text-base md:text-lg lg:text-xl text-[#7E8089] text-center">{t('blogs content')}{t('blogs content')}</p>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 lg:gap-x-10">
      {blogs.map((blog, key)=>{
          return(
            <div className="felx flex-col" key={key}>
            <img className='mb-2 md:mb-4' src={blog.image} alt="blogs" width={355} height={261} />
            <h4 className="mb-2 md:mb-4 text-base sm:text-lg md:text-xl font-bold leading-6">{blog.title}</h4>
            <p className="text-xs sm:text-sm md:text-base leading-4 sm:leading-5 text-[#7E8089]">{blog.description}</p>
            <button onClick={() => toBlogs(blog.id)} className="flex items-center gap-x-1 font-bold text-base leading-5 text-[#8C0F95] hover:scale-105">{t('read')} <img src={chevronRight} alt="right" width={24} height={24}/></button>
          </div>
          )
        })}
      </div>
    </div>
  )
}