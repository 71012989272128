export default function Company() {
  return (
    <div className="container pt-10 md:pt-[70px] pb-10 md:pb-20 text-[#051022]">
      <h2 className="mb-8 text-xl sm:text-2xl md:text-[32px] font-bold leading-8 max-lg:text-center">
        About company.
      </h2>
      <p className="max-w-[620px] max-lg:mx-auto max-lg:text-center ml-5 mb-2 text-sm md:text-base leading-6">
        MAXCYBERCOM team is a software engineering company driven by the idea of
        digital transformation and innovation that improve business processes.
        Our core engineering capabilities have been successfully implemented in
        various industries. We are here to provide real business value to our
        partners and customers through IT innovation.
      </p>
      <div className="flex justify-center max-lg:items-center lg:justify-between flex-col lg:flex-row">
        <div className="ml-0 md:ml-5">
          <p className="mb-5 font-semibold leading-6 text-xl">
            The key area of expertise of the team:
          </p>
          <ul className="leading-6 list-disc pl-6 pr-2 pb-1.5 text-sm md:text-base">
            <li>Core and Enterprise Systems</li>
            <li>Platform and Product Development</li>
            <li>API & Integration & Modernization</li>
            <li>MACH (Modern Composable Architecture)</li>
            <li>DevOps in Fintech , Edtech, Travel Tech</li>
            <li>DevOps inGIS (geographic information systems)</li>
            <li>DevOps of Mobile Applications</li>
            <li>Integration of AI</li>
            <li>Quality Engineering</li>
            <li>Technical support and maintenance</li>
          </ul>
        </div>
        <div className="relative">
          <div className="absolute top-0 right-0 -z-10 bg-[#376FFF] w-[115px] h-[115px] rounded-full"></div>
          <div className="w-full sm:max-w-[440px] max-sm:text-justify mt-12 mr-12 bg-[#051022] text-white p-9 sm:p-12 text-base sm:text-xl leading-7 sm:leading-8 font-semibold">We are proud of our competencies and capabilities, and we aim to provide innovative solutions that enable our clients to stay ahead of their competitors and succeed in the ever-changing technological landscape.</div>
        </div>
      </div>
    </div>
  );
}
