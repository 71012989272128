import coding from '../../assets/coding.svg';
import group from '../../assets/group.svg';
export default function Team(){
  return(
      <div className="container pt-4 pb-20 text-[#051022]">
      <h2 className='mb-6 md:mb-12 text-xl sm:text-2xl md:text-[32px] font-bold leading-8 max-md:text-center'>Team</h2>
      <div className="flex max-md:flex-col max-md:items-center md:justify-between gap-4 lg:gap-x-8 xl:gap-x-14">
        <div className="w-full sm:w-[530px] border border-[#7E808950] py-4 xl:py-8 px-5 xl:px-10">
          <img src={coding} alt="coding" width={56} height={56}/>
          <p className="mt-3 sm:mt-8 mb-2 text-base sm:text-lg md:text-[22px] font-semibold">Composition of the technical skills team:</p>
          <ul className="list-disc leading-6 pl-6 pr-2 pb-1.5">
            <li>5 project managers / IT architect</li>
            <li>4 project managers</li>
            <li>10 full stack developers</li>
            <li>4 full stack/devops</li>
            <li>9 backend developers</li>
            <li>7 frontend developers</li>
            <li>4 mobile app developers(android/iOS)</li>
            <li>4 designers</li>
          </ul>
        </div>
        <div className="w-full sm:w-[530px] border border-[#7E808950] py-4 xl:py-8 px-5 xl:px-10">
          <img src={group} alt="group" width={56} height={56}/>
          <p className="my-3 sm:my-8 text-base sm:text-lg md:text-[22px] font-semibold">A total of 20 employees of which:</p>
          <ul className="list-disc leading-6 pl-6 pr-2 pb-1.5 mb-8">
            <li>Architects - 5</li>
            <li>Seniors - 5</li>
            <li>Middles - 5</li>
            <li>Juniors - 5</li>
          </ul>
          <p>Also, there is a pool of competent specialists to involve in projects as needed.</p>
        </div>
      </div>
      </div>
  )
}