import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import { getData } from "../components/Requests";
export default function ServicesDetailsPage() {
  const { id } = useParams();
  const [services, setServices] = useState({});
  useLayoutEffect(() => {
    getData("/section/by-page-id?pageId=" + id)
      .then((res) => {
        setServices(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="container py-12 text-[#051022]">
      {services ? (
        <>
          <h2 className="mb-6 md:mb-8 lg:mb-11 text-xl md:text-2xl lg:text-[32px] font-bold leading-7 md:leading-10">
            {services.title}
          </h2>
          <img
            className="w-full mb-6 md:mb-8 lg:mb-12"
            src={services.images}
            alt="services"
          />
          <p
            className="mb-5 md:mb-10 text-lg lg:text-xl leading-6"
            dangerouslySetInnerHTML={{ __html: services.description }}
          ></p>
        </>
      ) : (
        <div className="text-lg text-center italic text-gray-500">
          Ma'lumot topilmadi!
        </div>
      )}
    </div>
  );
}
