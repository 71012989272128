import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import {getData} from '../Requests';
export default function WhatWeDo(){
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  useLayoutEffect(()=>{
    getData('/page/by-page-type-code?pageTypeCode=1')
    .then((res) =>{
      setServices(res.data)
    })
    .catch((err) =>{
      console.log(err);
    })
  },[])
  const toService = (id) =>{
    navigate("/services/" + id);
  }
  return(
    <div className="bg-[#051022] pt-12 pb-9" id='services'>
      <div className="container">
        <h2 className="mb-3 md:mb-6 font-bold text-xl sm:text-2xl md:text-[32px] text-white leading-8 text-center">{t('what we do')}</h2>
        <p className="max-w-[620px] mx-auto text-center mb-6 md:mb-11 text-base sm:text-xl text-[#959595] leading-6">Lorem ipsum dolor sit amet consectetur. Libero neque donec etiam pretium nibh molestie scelerisque urna justo</p>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 lg:gap-6">
            {services.map((service, key)=>{
              return(
              <button onClick={() => toService(service.id)} className="bg-[#FCFBFB] border border-[#051022] cursor-pointer hover:scale-95 hover:bg-[#D9D9D9] hover:shadow-[0_0_10px_2px_#D9D9D9] transition-all ease-in-out duration-500 py-4 min-[450px]:py-10 sm:py-16 lg:py-20 px-4 sm:px-10 lg:px-20 flex flex-col items-center justify-between min-h-[180px] sm:min-h-[280px] md:min-h-[320px] lg:min-h-[390px] rounded-[32px] shadow-[3px_3px_5px_0_#00000040]" key={key}>
                <img className='max-sm:scale-75 max-md:scale-90 max-w-[60px]' src={service.image} alt="programming"/>
                <h4 className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl text-center leading-6 md:leading-7 lg:leading-8">{service.title}</h4>
                <p className='max-w-[200px] text-[#7E8089] text-center text-xs sm:text-sm font-semibold truncate '>{service.description}</p>
              </button>
              )
            })}
        </div>
      </div>
    </div>
  )
}