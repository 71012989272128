import "./about.css";
import mission1 from '../../assets/mission-first.png';
import mission2 from '../../assets/mission-second.png';
import mission3 from '../../assets/mission-third.png';
import mission4 from '../../assets/mission-fourth.png';
export default function Mission() {
  return (
    <div className="container pt-4 pb-10 md:pb-20 text-[#051022]">
      <h2 className="mb-4 sm:mb-8 text-[#051022] text-xl sm:text-2xl md:text-[32px] font-bold leading-8">
        Our mission
      </h2>
      <p className="mb-6 sm:mb-10 text-xs sm:text-sm md:text-base leading-6">
        Our mission is to empower businesses with cutting-edge technology
        solutions that drive growth and transform the digital landscape. We
        strive to be the catalyst for innovation, delivering exceptional
        software development, web design, and mobile app solutions that propel
        our clients to new heights of success. <br />
        We are committed to providing unparalleled expertise, unwavering
        dedication, and customized IT solutions that meet the unique needs of
        each client. Our team of tech enthusiasts, creative thinkers, and
        problem solvers work collaboratively to deliver innovative and scalable
        solutions that drive business efficiency and competitiveness. <br />
        Through our relentless pursuit of excellence, we aim to redefine
        industry standards and exceed client expectations. We are passionate
        about leveraging the latest advancements in technology to empower
        businesses, enabling them to thrive in an ever-evolving digital
        landscape. <br />
        With a focus on long-term partnerships and exceptional customer service,
        we aim to build lasting relationships based on trust, transparency, and
        mutual growth. Our mission is not only to meet our clients' expectations
        but to exceed them, delivering solutions that empower their digital
        transformation and drive sustainable success. <br />
        Together, let's unlock the power of technology, navigate digital
        challenges, and unlock new opportunities for growth. Join us on this
        transformative journey as we shape the future of businesses through
        innovative and strategic IT solutions.
      </p>
      <div className="flex justify-center lg:justify-between gap-x-2 sm:gap-x-3">
        <img className="basis-1/4 w-[75px] min-[550px]:w-[120px] md:w-[180px] lg:w-auto h-auto" src={mission1} alt="mission" width={240}/>
        <img className="basis-1/4 w-[75px] min-[550px]:w-[120px] md:w-[180px] lg:w-auto h-auto" src={mission2} alt="mission" width={240}/>
        <img className="basis-1/4 w-[75px] min-[550px]:w-[120px] md:w-[180px] lg:w-auto h-auto" src={mission3} alt="mission" width={240}/>
        <img className="basis-1/4 w-[75px] min-[550px]:w-[120px] md:w-[180px] lg:w-auto h-auto" src={mission4} alt="mission" width={240}/>
      </div>
    </div>
  );
}
