import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import En from "./translations/en.json";
import Ru from "./translations/ru.json";
import Uz from "./translations/uz.json";

const resources = {
  Uz,
  En,
  Ru,
}
export const availableLanguages = Object.keys(resources)

i18n.use(initReactI18next)
  .init({
    resources,
    lng: window.localStorage.lang,
    defaultNS: "common",
    fallbackLng: "Uz",
  });