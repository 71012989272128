import chevronRight from '../../assets/chevron-right.svg';
import { useNavigate } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import {getData} from '../Requests';
export default function Positions(){
  const navigate = useNavigate();
  const [positions, setPositions] = useState([]);
  useLayoutEffect(()=>{
    getData('/page/by-page-type-code?pageTypeCode=6')
    .then((res) =>{
      setPositions(res.data);
    })
    .catch((err) =>{
      console.log(err);
    })
  },[])
  const toPositions = (id) =>{
    navigate("/careers/" + id);
  }
  return(
    <div className="container pt-2 sm:pt-4 pb-8 sm:pb-10 md:pb-16 text-[#051022]">
      <h2 className="mb-6 md:mb-12 text-xl sm:text-2xl md:text-[32px] font-bold leading-8 max-lg:text-center">
      Open positions
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 lg:gap-10">
        {positions.map((position, key)=>{
          return(
            <div className="felx flex-col" key={key}>
            <img className='mb-2 md:mb-4' src={position.image} alt="blogs" width={355} height={261} />
            <h4 className="mb-2 md:mb-4 text-base sm:text-lg md:text-xl font-bold leading-6">{position.title}</h4>
            <p className="text-xs sm:text-sm md:text-base leading-4 sm:leading-5 text-[#7E8089]">{position.description}</p>
            <button onClick={() => toPositions(position.id)} className="flex items-center gap-x-1 font-bold text-base leading-5 text-[#8C0F95] hover:scale-105">Read <img src={chevronRight} alt="right" width={24} height={24}/></button>
          </div>
          )
        })}
      </div>
    </div>
  )
}