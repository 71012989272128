import Hero from '../components/about/Hero';
import Company from '../components/about/Company';
import Mission from '../components/about/Mission';
import Team from '../components/about/Team';
export default function AboutPage(){
  return(
    <div  className="w-full h-full">
      <Hero/>
      <Company/>
      <Mission/>
      <Team/>
    </div>
  )
}