import logo from "../../assets/logo-dark.svg";
import right from "../../assets/right-footer.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#051022] pt-[50px] pb-[18px] text-white">
      <div className="container">
        <div className="flex pb-11 gap-x-10 xl:gap-x-28 max-lg:flex-wrap max-lg:justify-center">
          <div className="flex flex-col max-lg:mb-8">
            <NavLink
              to="/"
              className="mb-8 flex items-center text-[#1048A9] font-bold text-xs leading-4 gap-x-2"
            >
              {" "}
              <img src={logo} alt="logo" width={14} height={14} />
              MAXCYBERCOM
            </NavLink>
            <p className="mb-9 max-w-[420px] max-lg:text-justify font-normal text-base leading-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              ornare cursus sed nunc eget dictum Sed ornare cursus sed nunc eget
              dictumd nunc eget dictum Sed ornare cursus sed nunc eget dictum{" "}
            </p>
            <div className="flex items-center gap-x-11">
              <a
                href="https://www.linkedin.com/company/maxcybercom"
                className="social transition-all ease-in-out duration-500"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 3.5H12M4.5 0.5H10.5C12.7091 0.5 14.5 2.29086 14.5 4.5V10.5C14.5 12.7091 12.7091 14.5 10.5 14.5H4.5C2.29086 14.5 0.5 12.7091 0.5 10.5V4.5C0.5 2.29086 2.29086 0.5 4.5 0.5ZM7.5 10.5C5.84315 10.5 4.5 9.15685 4.5 7.5C4.5 5.84315 5.84315 4.5 7.5 4.5C9.15685 4.5 10.5 5.84315 10.5 7.5C10.5 9.15685 9.15685 10.5 7.5 10.5Z"
                    stroke="#1048A9"
                  />
                </svg>
              </a>
              <a href="https://Facebook.com/maxcybercom.uz" className="social transition-all ease-in-out duration-500">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 11.366 11.366 14.5 7.5 14.5ZM7.5 14.5V6.5C7.5 5.39543 8.39543 4.5 9.5 4.5H10M5 8.5H10"
                    stroke="#1048A9"
                  />
                </svg>
              </a>
              <a href="https://t.me/maxcyber_com" className="social transition-all ease-in-out duration-500">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 1.5L0.5 6.5L4.5 8.5L10.5 4.5L6.5 9.5L12.5 13.5L14.5 1.5Z"
                    stroke="#1048A9"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 sm:gap-x-10 xl:gap-x-24">
            <div className="flex flex-col gap-y-4 sm:gap-y-6">
              <h4 className="font-medium text-base sm:text-xl leading-6">
                {t('company')}
              </h4>
              <NavLink
                to="/about"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Our mission
              </NavLink>
              <NavLink
                to="/about"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                {t('team')}
              </NavLink>
              <NavLink
                to="/about"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Careers
              </NavLink>
              <NavLink
                to="/contacts"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                {t('contact')}
              </NavLink>
            </div>
            <div className="flex flex-col gap-y-4 sm:gap-y-6">
              <h4 className="font-medium text-base sm:text-xl leading-6">
              {t('projects')}
              </h4>
              <NavLink
                to="/"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Case studies
              </NavLink>
              <NavLink
                to="/"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Button
              </NavLink>
              <NavLink
                to="/"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Button
              </NavLink>
              <NavLink
                to="/"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Button
              </NavLink>
            </div>
            <div className="flex flex-col gap-y-4 sm:gap-y-6">
              <h4 className="font-medium text-base sm:text-xl leading-6 whitespace-nowrap">
              {t('industries')}
              </h4>
              <NavLink
                to="/"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Logistics
              </NavLink>
              <NavLink
                to="/"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Travel
              </NavLink>
              <NavLink
                to="/"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Fintech
              </NavLink>
              <NavLink
                to="/"
                className="font-semibold text-sm leading-4 flex items-center gap-x-1 sm:gap-x-2.5 hover:underline underline-offset-4"
              >
                <img src={right} alt="right" width={18} height={18} />
                Education
              </NavLink>
            </div>
          </div>
        </div>
        <div className="border-t border-t-[#FFFFFF33] pt-6 flex items-center justify-between">
          <div className="font-light text-sm leading-4">
            2024 <br />
            Maxcybercom LLC
          </div>
          <div className="font-light text-sm leading-4">
            2023 <br />
            Maxcybercom LLC
          </div>
        </div>
      </div>
    </div>
  );
}
