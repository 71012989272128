export default function Bottom(){
  return(
    <div className="bg-[#051022] py-[70px]">
      <div className="container">
        <h4 className="mb-4 md:mb-8 text-lg sm:text-xl md:text-2xl font-bold leading-7 text-white text-center">
        Have a project? lets talk about it.
        </h4>
        <button className="block bg-white mx-auto py-1.5 md:py-2.5 px-8 md:px-14 text-xs md:text-sm font-bold text-[#376FFF] border-[3px] border-[#376FFF] rounded-2xl shadow-[0_0_17px_#376FFF] hover:scale-105">
        Get in touch!
        </button>
      </div>
    </div>
  )
}