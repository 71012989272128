import Hero from '../components/contacts/Hero';
import Contacts from '../components/home/Contacts';
import Socials from '../components/contacts/Socials';
import Address from '../components/contacts/Address';
export default function ContactsPage(){
  return(
    <div className="">
      <Hero/>
      <Contacts/>
      <Socials/>
      <Address/>
    </div>
  )
}