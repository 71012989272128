import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ServicesPage from "./pages/ServicesPage";
import ServicesDetailsPage from "./pages/ServicesDetailsPage";
import BlogsPage from "./pages/BlogsPage";
import BlogsDetailsPage from "./pages/BlogsDetailsPage";
import ContactsPage from "./pages/ContactsPage";
import CareersPage from "./pages/CareersPage";
import CareersDetailsPage from "./pages/CareersDetailsPage";
import PortfolioPage from "./pages/PortfolioPage";
import PortfolioDetailsPage from "./pages/PortfolioDetailsPage";
function App() {
  const basename = document.querySelector("base")?.getAttribute("href") ?? "/";
  return (
    <div className="App">
    <HashRouter basename={basename}>
      <Navbar/>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
        <Route path="/services" element={<ServicesPage/>}/>
        <Route path="/services/:id" element={<ServicesDetailsPage/>}/>
        <Route path="/careers" element={<CareersPage/>}/>
        <Route path="/careers/:id" element={<CareersDetailsPage/>}/>
        {/* <Route path="/portfolio" element={<PortfolioPage/>}/>
        <Route path="/portfolio/:id" element={<PortfolioDetailsPage/>}/> */}
        <Route path="/blogs" element={<BlogsPage/>}/>
        <Route path="/blogs/:id" element={<BlogsDetailsPage/>}/>
        <Route path="/contacts" element={<ContactsPage/>}/>
      </Routes>
      <Footer/>
    </HashRouter>
    </div>
  );
}

export default App;