import './home.css';
import { useTranslation } from "react-i18next";
export default function Industries(){
  const { t } = useTranslation();
  return(
    <div className="container text-[#051022] pt-6 md:pt-10 pb-10 md:pb-16 lg:pb-28" id='industries'>
      <h2 className="mb-6 md:mb-11 text-lg md:text-2xl lg:text-[32px] font-bold text-center leading-10">{t('industries title')}</h2>
      <div className="grid grid-cols-2 gap-2 mb-2">
        <div className="fintech flex items-center justify-center font-bold text-base sm:text-lg md:text-2xl lg:text-[32px] leading-10 text-center text-white">Fintech</div>
        <div className="travel flex items-center justify-center font-bold text-base sm:text-lg md:text-2xl lg:text-[32px] leading-10 text-center text-white">Travel</div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-1.5">
      <div className="networking flex items-center justify-center font-bold text-base sm:text-lg md:text-xl lg:text-2xl leading-7 text-center text-white">Social networking</div>
        <div className="medicine flex items-center justify-center font-bold text-base sm:text-lg md:text-xl lg:text-2xl leading-7 text-center text-white">Medicine</div>
        <div className="education flex items-center justify-center font-bold text-base sm:text-lg md:text-xl lg:text-2xl leading-7 text-center text-white">Education</div>
        <div className="government flex items-center justify-center font-bold text-base sm:text-lg md:text-xl lg:text-2xl leading-7 text-center text-white">Government</div>
      </div>
    </div>
  )
}