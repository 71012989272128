import Hero from '../components/careers/Hero';
import Culture from '../components/careers/Culture';
import Positions from '../components/careers/Positions';
import Form from '../components/careers/Form';
export default function CareersPage(){
  return(
    <div>
      <Hero/>
      <Culture/>
      <Positions/>
      <Form/>
    </div>
  )
}