import './careers.css';
import { useTranslation } from "react-i18next";
export default function Hero(){
  const { t } = useTranslation();
  return(
    <div className="hero-wrapper">
      <div className="container pt-20 md:pt-24 pb-16 md:pb-20">
      <h1 className='mb-3 md:mb-8 text-center text-xl min-[450px]:text-2xl md:text-[48px] font-bold leading-8 md:leading-[60px] text-white'>{t('careers')}</h1>
        <p className='max-w-[620px] mx-auto text-center text-white text-sm sm:text-base md:text-xl'>He’s so similar to me. In person, we’re just weird and silly and stupid together.</p>
      </div>
    </div>
  )
}