import axios from "axios";
import { axiosInstance } from "../utils/axios";
export function getData(name) {
  let result = axiosInstance
    .get(name)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return result;
}