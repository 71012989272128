import './home.css';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
export default function Hero(){
  const { t } = useTranslation();
  return (
    <div className="hero">
      <div className="container pt-40 pb-20">
        <h1 className='mb-2 text-center text-3xl min-[450px]:text-4xl sm:text-[50px] font-bold leading-8 sm:leading-[60px] text-white'>{t('hero title')}</h1>
        <p className='max-w-[620px] mx-auto mb-28 sm:mb-36 text-center text-white text-base sm:text-xl font-medium'>{t('hero content')}</p>
        <NavLink to="/contacts" className="block max-w-[300px] text-center mx-auto py-3 px-16 bg-white border-4 border-[#1048A9] shadow-[1px_1px_15px_0_#0F4BB3] text-[#0F4BB3] text-base font-bold rounded-2xl hover:scale-105 transition-all ease-in-out duration-500 btn-title">{t('contact us')}</NavLink>
      </div>
    </div>
  )
}