import programming from '../../assets/programming.svg';
import mobile from '../../assets/mobile.svg';
import pm from '../../assets/pm.svg';
import analytics from '../../assets/analytics.svg';
import devops from '../../assets/devops.svg';
import callCenter from '../../assets/call-center.svg';
import { useNavigate } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import {getData} from '../Requests';
export default function WhatWeDo(){
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  useLayoutEffect(()=>{
    getData('/page/by-page-type-code?pageTypeCode=1')
    .then((res) =>{
      setServices(res.data)
    })
    .catch((err) =>{
      console.log(err);
    })
  },[])
  const toService = (id) =>{
    navigate("/services/" + id);
  }
  return(
    <div className="container pt-12 pb-9" id='services'>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 lg:gap-6">
      {services.map((service, key)=>{
              return(
                <button onClick={() => toService(service.id)} className="bg-[#FCFBFB] border border-[#051022] cursor-pointer hover:scale-95 transition-all ease-in-out duration-500 py-4 min-[450px]:py-10 sm:py-16 lg:py-20 px-4 sm:px-10 lg:px-20 flex flex-col items-center justify-between min-h-[180px] sm:min-h-[280px] md:min-h-[320px] lg:min-h-[390px] rounded-[32px] shadow-[3px_3px_5px_0_#00000040]">
                <img className='max-sm:scale-75 max-md:scale-90 max-w-[60px]' src={service.image} alt="programming"/>
                <h4 className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl text-center leading-6 md:leading-7 lg:leading-8">{service.title}</h4>
                <p className='max-w-[200px] text-[#7E8089] text-center text-xs sm:text-sm font-semibold truncate'>{service.description}</p>
              </button>
              )
            })}
        {/* <button  onClick={() => toService(1)} className="bg-[#FCFBFB] border border-[#051022] cursor-pointer hover:scale-95 transition-all ease-in-out duration-500 py-4 min-[450px]:py-10 sm:py-16 lg:py-20 px-4 sm:px-10 lg:px-20 flex flex-col items-center justify-between min-h-[180px] sm:min-h-[280px] md:min-h-[320px] lg:min-h-[390px] rounded-[32px] shadow-[3px_3px_5px_0_#00000040]">
          <img className='max-sm:scale-75 max-md:scale-90' src={programming} alt="programming" width={51} height={47}/>
          <h4 className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl text-center leading-6 md:leading-7 lg:leading-8">Programming & Development</h4>
          <p className='text-[#7E8089] text-center text-xs sm:text-sm font-semibold'>Lorem ipsum dolor sit</p>
        </button>
        <button onClick={() => toService(2)} className="bg-[#FCFBFB] border border-[#051022] cursor-pointer hover:scale-95 transition-all ease-in-out duration-500 py-4 min-[450px]:py-10 sm:py-16 lg:py-20 px-4 sm:px-10 lg:px-20 flex flex-col items-center justify-between min-h-[180px] sm:min-h-[280px] md:min-h-[320px] lg:min-h-[390px] rounded-[32px] shadow-[3px_3px_5px_0_#00000040]">
          <img className='max-sm:scale-75 max-md:scale-90' src={mobile} alt="mobile" width={50} height={52}/>
          <h4 className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl text-center leading-6 md:leading-7 lg:leading-8">Mobile app development</h4>
          <p className='text-[#7E8089] text-center text-xs sm:text-sm font-semibold'>Lorem ipsum dolor sit</p>
        </button>
        <button onClick={() => toService(3)} className="bg-[#FCFBFB] border border-[#051022] cursor-pointer hover:scale-95 transition-all ease-in-out duration-500 py-4 min-[450px]:py-10 sm:py-16 lg:py-20 px-4 sm:px-10 lg:px-20 flex flex-col items-center justify-between min-h-[180px] sm:min-h-[280px] md:min-h-[320px] lg:min-h-[390px] rounded-[32px] shadow-[3px_3px_5px_0_#00000040]">
          <img className='max-sm:scale-75 max-md:scale-90' src={pm} alt="pm" width={47} height={42}/>
          <h4 className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl text-center leading-6 md:leading-7 lg:leading-8">Project management</h4>
          <p className='text-[#7E8089] text-center text-xs sm:text-sm font-semibold'>Lorem ipsum dolor sit</p>
        </button>
        <button onClick={() => toService(4)} className="bg-[#FCFBFB] border border-[#051022] cursor-pointer hover:scale-95 transition-all ease-in-out duration-500 py-4 min-[450px]:py-10 sm:py-16 lg:py-20 px-4 sm:px-10 lg:px-20 flex flex-col items-center justify-between min-h-[180px] sm:min-h-[280px] md:min-h-[320px] lg:min-h-[390px] rounded-[32px] shadow-[3px_3px_5px_0_#00000040]">
          <img className='max-sm:scale-75 max-md:scale-90' src={analytics} alt="analytics" width={56} height={56}/>
          <h4 className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl text-center leading-6 md:leading-7 lg:leading-8">Business Analytics</h4>
          <p className='text-[#7E8089] text-center text-xs sm:text-sm font-semibold'>Lorem ipsum dolor sit</p>
        </button>
        <button onClick={() => toService(5)} className="bg-[#FCFBFB] border border-[#051022] cursor-pointer hover:scale-95 transition-all ease-in-out duration-500 py-4 min-[450px]:py-10 sm:py-16 lg:py-20 px-4 sm:px-10 lg:px-20 flex flex-col items-center justify-between min-h-[180px] sm:min-h-[280px] md:min-h-[320px] lg:min-h-[390px] rounded-[32px] shadow-[3px_3px_5px_0_#00000040]">
          <img className='max-sm:scale-75 max-md:scale-90' src={devops} alt="devops" width={56} height={56}/>
          <h4 className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl text-center leading-6 md:leading-7 lg:leading-8">Devops <br /> <br /></h4>
          <p className='text-[#7E8089] text-center text-xs sm:text-sm font-semibold'>Lorem ipsum dolor sit</p>
        </button>
        <button onClick={() => toService(6)} className="bg-[#FCFBFB] border border-[#051022] cursor-pointer hover:scale-95 transition-all ease-in-out duration-500 py-4 min-[450px]:py-10 sm:py-16 lg:py-20 px-4 sm:px-10 lg:px-20 flex flex-col items-center justify-between min-h-[180px] sm:min-h-[280px] md:min-h-[320px] lg:min-h-[390px] rounded-[32px] shadow-[3px_3px_5px_0_#00000040]">
          <img className='max-sm:scale-75 max-md:scale-90' src={callCenter} alt="callCenter" width={51} height={47}/>
          <h4 className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl text-center leading-6 md:leading-7 lg:leading-8">Call center <br /> 24/7</h4>
          <p className='text-[#7E8089] text-center text-xs sm:text-sm font-semibold'>Lorem ipsum dolor sit</p>
        </button> */}
      </div>
    </div>
  )
}