import linkedin from "../../assets/linkedin-contact.svg";
import instagram from "../../assets/instagram-contact.svg";
import telegram from "../../assets/telegram-contact.svg";
import facebook from "../../assets/facebook-contact.svg";
export default function Socials() {
  return (
    <div className="container py-8 sm:py-10 md:py-16 text-[#051022]">
      <h2 className="mb-4 md:mb-6 text-lg sm:text-xl md:text-2xl lg:text-[32px] font-bold leading-8 text-center">
        We are on social networks
      </h2>
      <p className="max-w-[620px] mx-auto mb-6 sm:mb-10 md:mb-14 text-center text-[#959595] text-sm sm:text-base md:text-xl">
        Lorem ipsum dolor sit amet consectetur. Libero neque donec etiam pretium
        nibh molestie scelerisque urna justo
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 max-md:gap-6 md:gap-x-3 lg:gap-x-8">
        <div className="p-2 sm:p-4 lg:p-6 pt-8 flex flex-col items-center">
          <img className="mb-2 md:mb-4" src={linkedin} alt="Linkedin" />
          <p className="mb-1.5 md:mb-3 text-sm md:text-base text-center">Linkedin</p>
          <p className="mb-2.5 md:mb-5 text-sm md:text-base text-center text-[#959595]">
            1234 followers
          </p>
          <a href="https://www.linkedin.com/company/maxcybercom" className="bg-[#F0F0F0] rounded-sm py-1.5 px-10 md:px-14 text-base text-center hover:scale-90">
            Follow
          </a>
        </div>
        <div className="p-2 sm:p-4 lg:p-6 pt-8 flex flex-col items-center">
          <img className="mb-2 md:mb-4" src={instagram} alt="Instagram" />
          <p className="mb-1.5 md:mb-3 text-sm md:text-base text-center">Instagram</p>
          <p className="mb-2.5 md:mb-5 text-sm md:text-base text-center text-[#959595]">
            1234 followers
          </p>
          <a href="https://t.me/maxcyber_com" className="bg-[#F0F0F0] rounded-sm py-1.5 px-10 md:px-14 text-base text-center hover:scale-90">
            Follow
          </a>
        </div>
        <div className="p-2 sm:p-4 lg:p-6 pt-8 flex flex-col items-center">
          <img className="mb-2 md:mb-4" src={telegram} alt="Telegram" />
          <p className="mb-1.5 md:mb-3 text-sm md:text-base text-center">Telegram</p>
          <p className="mb-2.5 md:mb-5 text-sm md:text-base text-center text-[#959595]">
            1234 followers
          </p>
          <a href="https://t.me/maxcyber_com" className="bg-[#F0F0F0] rounded-sm py-1.5 px-10 md:px-14 text-base text-center hover:scale-90">
            Follow
          </a>
        </div>
        <div className="p-2 sm:p-4 lg:p-6 pt-8 flex flex-col items-center">
          <img className="mb-2 md:mb-4" src={facebook} alt="Facebook" />
          <p className="mb-1.5 md:mb-3 text-sm md:text-base text-center">Facebook</p>
          <p className="mb-2.5 md:mb-5 text-sm md:text-base text-center text-[#959595]">
            1234 followers
          </p>
          <a href="https://Facebook.com/maxcybercom.uz" className="bg-[#F0F0F0] rounded-sm py-1.5 px-10 md:px-14 text-base text-center hover:scale-90">
            Follow
          </a>
        </div>
      </div>
    </div>
  );
}
