import { useParams } from "react-router-dom";
import blog from "../assets/blog-hero.png";
import { useState, useLayoutEffect } from "react";
import {getData} from '../components/Requests';
import Form from "../components/careers/Form";
export default function CareersDetailsPage() {
  const { id } = useParams();
  const [careers, setCareers] = useState({});
  useLayoutEffect(()=>{
    getData('/section/by-page-id?pageId='+ id)
    .then((res) =>{
      setCareers(res.data[0])
    })
    .catch((err) =>{
      console.log(err);
    })
  },[])
  return (
    <div className="container py-12 text-[#051022]">
      {careers ? (
        <>        
        <h2 className="mb-6 md:mb-8 lg:mb-11 text-xl md:text-2xl lg:text-[32px] font-bold leading-7 md:leading-10">
          {careers.title}
        </h2>
        <img className="w-full mb-6 md:mb-8 lg:mb-12" src={careers.images} alt="blog" />
        <p className="mb-5 md:mb-10 text-lg lg:text-xl leading-6" dangerouslySetInnerHTML={{ __html: careers.description }}></p>
        <div className="relative pb-28">
      <div className="absolute top-0 left-0 right-0 bg-[#F8F8FA] -z-10 w-full h-[400px]"></div>
      <div className="pt-16 text-center mb-6">
        <h2 className="mb-4 md:mb-8 max-w-[600px] mx-auto text-xl sm:text-2xl md:text-[32px] font-bold leading-8 max-lg:text-center">
        Join our team and embark on an exciting career journey with us.
        </h2>
        <p className="max-w-[750px] mx-auto text-sm md:text-base leading-5 sm:leading-6">
        To apply for this position please send your resume and cover letter to [email protected] We look forward to hearing from you and potentially welcoming you to our team.
        </p>
      </div>
      <div className="max-w-[750px] mx-auto bg-white border border-[#7E808920] p-3 sm:p-6">
        <h4 className="mb-2 sm:mb-3.5 text-lg sm:text-xl font-semibold leading-6">
          Tell us about yourself
        </h4>
        <p className="max-w-[420px] mb-4 text-[#959595] text-sm sm:text-base leading-5 sm:leading-6 max-sm:text-justify">
          He’s so similar to me. In person, we’re just weird and silly and
          stupid together.
        </p>
        <form action="">
          <input
            className="w-full mb-3 sm:mb-5 bg-[#FCFBFB] border border-[#7E808933] rounded-sm px-3.5 py-2.5 text-sm text-[#7E7E7E] leading-4 font-light focus:outline-none"
            type="text"
            name="fio"
            id="fio"
            placeholder="Full name"
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-x-6 mb-4">
            <input
              className="w-full bg-[#FCFBFB] border border-[#7E808933] rounded-sm px-3.5 py-2.5 text-sm text-[#7E7E7E] leading-4 font-light focus:outline-none"
              type="tel"
              name="phone"
              id="phone"
              placeholder="Phone number"
            />
            <input
              className="w-full bg-[#FCFBFB] border border-[#7E808933] rounded-sm px-3.5 py-2.5 text-sm text-[#7E7E7E] leading-4 font-light focus:outline-none"
              type="email"
              name="email"
              id="email"
              placeholder="Email"
            />
          </div>
          <p className="mb-4 font-bold text-base leading-6">File Containing resume</p>
          <input
            className="w-full sm:w-1/2 mb-5 bg-[#FCFBFB] border border-[#7E808933] rounded-sm px-3.5 py-2.5 text-sm text-[#7E7E7E] leading-4 font-light focus:outline-none"
            type="file"
            name="file"
            id="file"
            placeholder="Choose a file"
          />
          <button
            className="w-full text-center bg-[#051022] border-2 border-[#051022] hover:bg-transparent hover:text-[#051022] rounded-lg py-2.5 font-bold text-white text-sm leading-4"
            type="submit"
          >
            Apply
          </button>
        </form>
      </div>
    </div>
        </>
      ): (
        <div className="text-lg text-center italic text-gray-500">Ma'lumot topilmadi!</div>
      )}
    </div>
  );
}
