import culture1 from '../../assets/culture1.png' 
import culture2 from '../../assets/culture2.png' 
import culture3 from '../../assets/culture3.png' 
import culture4 from '../../assets/culture4.png' 
export default function Culture(){
  return(
    <div className="container py-10 md:py-14 lg:py-[72px] text-[#051022]">
      <h2 className="mb-4 md:mb-8 text-xl sm:text-2xl md:text-[32px] font-bold leading-8 max-lg:text-center">
      Our culture
      </h2>
      <p className="w-full sm:max-w-[500px] mb-8 md:mb-14 text-sm sm:text-base leading-5 sm:leading-6 max-sm:text-justify max-lg:text-center max-lg:mx-auto">
      At MAXCYBERCOM, our culture is the foundation of who we are and how we operate. We foster a positive and inclusive work environment that values collaboration, innovation, and mutual respect. Our company culture is built on the following principles:
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-10 mx-0 md:mx-6 mb-8 md:mb-14">
        <p className="text-sm md:text-base leading-5 sm:leading-6">
        1. Collaboration and Teamwork: We believe that great things are achieved through collaboration. We encourage open communication, knowledge sharing, and teamwork across all levels of the organization. We foster an environment where diverse perspectives are valued and contribute to our collective success.
        </p>
        <p className="text-sm md:text-base leading-5 sm:leading-6">
        2. Innovation and Creativity: We embrace innovation and encourage our team members to think creatively. We empower individuals to explore new ideas, take calculated risks, and challenge the status quo. We believe that innovation is the key to staying ahead in a rapidly evolving industry.
        </p>
        <p className="text-sm md:text-base leading-5 sm:leading-6">
        3. Continuous Learning and Growth: We are committed to the professional and personal growth of our team members. We provide opportunities for learning, skill development, and career advancement. We encourage continuous improvement and support individuals in reaching their full potential.
        </p>
        <p className="text-sm md:text-base leading-5 sm:leading-6">
        4. Integrity and Ethical Conduct: We uphold the highest standards of integrity, honesty, and ethical conduct. We believe in doing the right thing, even when faced with difficult choices. We foster a culture of transparency, trust, and accountability in all our interactions.
        </p>
      </div>
      <div className="grid grid-cols-2 min-[550px]:grid-cols-4 gap-3 sm:gap-5">
        <img src={culture1} alt="culture" />
        <img src={culture2} alt="culture" />
        <img src={culture3} alt="culture" />
        <img src={culture4} alt="culture" />
      </div>
    </div>
  )
}