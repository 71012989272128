import "./home.css";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
export default function WhoWeAre() {
  const { t } = useTranslation();
  return (
    <div className="container py-10 md:py-20 text-[#051022]" id="about">
      <div className="flex flex-col md:flex-row justify-between gap-x-6 xl:gap-x-12">
        <div className="max-md:max-w-[500px] mx-auto md:basis-1/2 lg:basis-3/5 pr-3">
          <h2 className="mb-4 md:mb-7 text-xl sm:text-2xl md:text-[32px] font-extrabold text-center text-[#1048A9]">
          {t('who we are')}<span className="text-[#8C0F95]">?</span>
          </h2>
          <p className="text-justify text-xs sm:text-base md:text-lg font-medium">
          {t('about content')}
          </p>
        </div>
        <div className="max-[500px]:hidden max-md:max-w-[500px] mx-auto max-md:my-4 md:basis-1/2 lg:basis-2/5 p-3 lg:p-5 flex items-center justify-center rounded shadow-[inset_-8px_0px_5px_-5px_#0F4BB3,inset_8px_0px_5px_-5px_#0F4BB3] about">
          <Swiper
          direction={'vertical'}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[ Autoplay]}
          className="mySwiper"
          >
            <SwiperSlide>
            <div className="mb-2 lg:mb-4 font-bold text-base min-[450px]:text-lg sm:text-xl md:text-2xl text-[#1048A9] underline decoration-[#1048A9] underline-offset-4 bg-white">
            {t('practice')}
            </div>
            <div className="w-full">
              <ul className="leading-6 md:leading-7 font-medium text-sm md:text-base">
                <li>Core and Enterprise Systems</li>
                <li>Platform and Product Development</li>
                <li>API & Integration & Modernization</li>
                <li>MACH (Modern Composable Architecture)</li>
                <li>DevOps in Fintech , Edtech, Travel Tech</li>
                <li>DevOps inGIS (geographic information systems)</li>
                <li>DevOps of Mobile Applications</li>
                <li>Integration of AI</li>
                <li>Quality Engineering</li>
                <li>Technical support and maintenance</li>
              </ul>
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="mb-2 lg:mb-4 font-bold text-base min-[450px]:text-lg sm:text-xl md:text-2xl text-[#1048A9] underline decoration-[#1048A9] underline-offset-4 bg-white">
            {t('ai')}
            </div>
            <div className="w-full">
              <ul className="leading-6 md:leading-7 font-medium text-sm md:text-base">
                <li>Core and Enterprise Systems</li>
                <li>Platform and Product Development</li>
                <li>API & Integration & Modernization</li>
                <li>MACH (Modern Composable Architecture)</li>
                <li>DevOps in Fintech , Edtech, Travel Tech</li>
                <li>DevOps inGIS (geographic information systems)</li>
                <li>DevOps of Mobile Applications</li>
                <li>Integration of AI</li>
                <li>Quality Engineering</li>
                <li>Technical support and maintenance</li>
              </ul>
            </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}