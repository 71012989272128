import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import logo from "../../assets/logo.svg";
import menu from "../../assets/menu-right.svg";
export default function Navbar() {
  const { t } = useTranslation();
  return (
    <div className="border-b-2 border-[#051022]">
      <div className="site-top py-2 bg-[#00081D] text-white">
        <div className="container flex justify-between items-center">
          <div className="flex items-center sm:gap-x-3 font-semibold text-xs">
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 1.70312C14.5822 1.70312 18.2969 5.41776 18.2969 10C18.2969 14.5822 14.5822 18.2969 10 18.2969C5.41776 18.2969 1.70312 14.5822 1.70312 10C1.70312 5.41776 5.41776 1.70312 10 1.70312ZM13.0222 11.1664L14.2464 12.184C15.2019 13.2812 12.6042 16.7027 8.02217 12.1187L7.95264 12.0488L7.8831 11.9797C3.29912 7.39412 6.72139 4.79803 7.81787 5.7535L8.83349 6.97772C9.60138 7.74561 7.91311 9.10275 7.84939 9.15397C7.84825 9.15489 7.84763 9.15538 7.84756 9.15545C7.73935 9.26405 8.43584 10.1371 9.14951 10.8504C9.86318 11.5637 10.7358 12.2605 10.8444 12.1523C10.8445 12.1522 10.8453 12.1513 10.8468 12.1494C10.9107 12.0703 12.2585 10.4027 13.0222 11.1664Z"
                  fill="#ffffff"
                />
              </svg>
            </span>
            <a className="mr-1 hover:underline " href="tel:+998337000901">
              +998 33 700-09-01
            </a>
            |<span className="ml-1">24/7</span>
          </div>
          <div className="flex gap-x-3 sm:gap-x-7">
            <LanguageSelector/>
            <a
              href="https://www.linkedin.com/company/maxcybercom"
              className="social"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 3.5H12M4.5 0.5H10.5C12.7091 0.5 14.5 2.29086 14.5 4.5V10.5C14.5 12.7091 12.7091 14.5 10.5 14.5H4.5C2.29086 14.5 0.5 12.7091 0.5 10.5V4.5C0.5 2.29086 2.29086 0.5 4.5 0.5ZM7.5 10.5C5.84315 10.5 4.5 9.15685 4.5 7.5C4.5 5.84315 5.84315 4.5 7.5 4.5C9.15685 4.5 10.5 5.84315 10.5 7.5C10.5 9.15685 9.15685 10.5 7.5 10.5Z"
                  stroke="#ffffff"
                />
              </svg>
            </a>
            <a href="https://Facebook.com/maxcybercom.uz" className="social">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 11.366 11.366 14.5 7.5 14.5ZM7.5 14.5V6.5C7.5 5.39543 8.39543 4.5 9.5 4.5H10M5 8.5H10"
                  stroke="#ffffff"
                />
              </svg>
            </a>
            <a href="https://t.me/maxcyber_com" className="social">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 1.5L0.5 6.5L4.5 8.5L10.5 4.5L6.5 9.5L12.5 13.5L14.5 1.5Z"
                  stroke="#ffffff"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="container py-5 flex items-center justify-between">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "flex items-center hover:scale-105 transition-all ease-in-out duration-500"
              : "flex items-center hover:scale-105 transition-all ease-in-out duration-500"
          }
        >
          <img src={logo} alt="Logo" className="w-7 h-7 mr-2" />
          <span className="font-bold text-[#1048A9]">MAXCYBERCOM</span>
        </NavLink>
        <div className="flex items-center gap-x-10 text-[#051022] font-semibold max-lg:text-sm max-[850px]:hidden">
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive
                ? "text-[#1048A9] underline underline-offset-2"
                : "hover:text-[#1048A9] transition-all ease-in-out duration-500"
            }
          >
            {t("who we are")}
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive
                ? "text-[#1048A9] underline underline-offset-2"
                : "hover:text-[#1048A9] transition-all ease-in-out duration-500"
            }
          >
            {t("services")}
          </NavLink>
          <NavLink
            to="/careers"
            className={({ isActive }) =>
              isActive
                ? "text-[#1048A9] underline underline-offset-2"
                : "hover:text-[#1048A9] transition-all ease-in-out duration-500"
            }
          >
            {t("careers")}
          </NavLink>
          {/* <NavLink
            to="/portfolio"
            className={({ isActive }) =>
              isActive
                ? "text-[#1048A9] underline underline-offset-2"
                : "hover:text-[#1048A9] transition-all ease-in-out duration-500"
            }
          >
            {t("portfolio")}
          </NavLink> */}
          <NavLink
            to="/blogs"
            className={({ isActive }) =>
              isActive
                ? "text-[#1048A9] underline underline-offset-2"
                : "hover:text-[#1048A9] transition-all ease-in-out duration-500"
            }
          >
            {t("insights")}
          </NavLink>
          <NavLink
            to="/contacts"
            className={({ isActive }) =>
              isActive
                ? "text-[#1048A9] underline underline-offset-2"
                : "hover:text-[#1048A9] transition-all ease-in-out duration-500"
            }
          >
            {t("contacts")}
          </NavLink>
        </div>
        <button className="min-[851px]:hidden">
          <img src={menu} alt="menu" />
        </button>
      </div>
    </div>
  );
}
